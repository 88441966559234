import { ref } from 'vue';

function putSelectorAtEnd(domInputElement){
  let range = document.createRange();
  range.selectNodeContents(domInputElement);
  range.collapse(false);
  let selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

function selectWholeText(domInputElement) {
  let range = document.createRange();
  range.selectNodeContents(domInputElement);
  let selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

async function saveItemJsonData(dataObject, itemRow, jsonData) {
    let updatedChecklists = jsonData.checklists?.map(cl => {
        let updatedChecklistItems = cl.checklistItems.map(ci => {
        if (!ci.hasChanges) {
            return {
            name: ci.Name,
            value: ci.value
            };
        } else {
            return {
            name: ci.oldValues.Name,
            value: ci.oldValues.value
            };
        }
        });

        return {
        checklistItems: updatedChecklistItems,
        name: !cl.name.hasChanges ? cl.name.Name : cl.name.oldValues.Name
        };
    });

    let updatedItem = {
        checklists: updatedChecklists
    };
    itemRow.JsonData = JSON.stringify(updatedItem);

    console.log(itemRow.JsonData);
    dataObject.save()
}

export { putSelectorAtEnd, selectWholeText, saveItemJsonData }